.sustainabilityContainerStyle {
  background-image: url("../../../../assets/Images/sustainabilityBgImg.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.sustainabilityOutSideCOntainerStyle {
  backdrop-filter: blur(8px);
}
.sustainabilityBlurContainerStyle {
  max-width: 1440px;
  margin: auto;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 308.5px 540px;
}
.sustainabilityInsideContainerStyle {
  width: 840px;
  position: relative;
  display: flex;
  height: 232px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sustainabilityTitleTextStyle {
  max-width: 452px;
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 48px;
  line-height: 64px;
  color: #fff8f8;
  text-align: left;
}
.sustainabilityDescContainerStyle {
  max-width: 420px;
  position: absolute;
  top: 100px;
  right: 0px;
}
.sustainabilityTitleDescriptionTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #ebebeb;
  text-align: left;
  margin-bottom: 32px;
}
.sustainabilitylearnMoreContainerStyle {
  width: 130px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.learnMoreTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
  margin-left: 10px;
}

@media screen and (max-width: 1440px) {
  .learnMoreTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1366px) {
  .sustainabilityBlurContainerStyle {
    padding: 221.5px 220px;
  }
  .sustainabilityTitleDescriptionTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .learnMoreTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1279px) {
  .sustainabilityBlurContainerStyle {
    padding: 179px 140px;
  }
  .sustainabilityDescContainerStyle {
    top: 130px;
  }
}

@media screen and (max-width: 1023px) {
  .sustainabilityBlurContainerStyle {
    padding: 120px 244px;
  }
  .sustainabilityTitleTextStyle {
    font-size: 40px;
    line-height: 56px;
  }
  .sustainabilityTitleDescriptionTextStyle {
    margin-bottom: 24px;
  }
  .sustainabilityInsideContainerStyle {
    max-width: 452px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 256px;
  }
  .sustainabilityDescContainerStyle {
    max-width: 404px;
    top: 125px;
  }
}
@media screen and (max-width: 867px) {
  .sustainabilityBlurContainerStyle {
    padding: 120px 108px;
  }
}

@media screen and (max-width: 559px) {
  .sustainabilityTitleTextStyle {
    max-width: 366px;
  }
  .sustainabilityBlurContainerStyle {
    padding: 202px 24px;
  }
  .sustainabilityInsideContainerStyle {
    max-width: 320px;
    height: 256px;
  }
  .sustainabilityDescContainerStyle {
    max-width: 334px;
    padding-left: 48px;
  }
}
