.aboutSubscriptionsMainConatinerStyle {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.aboutSubscriptionsHeaderContainerStyle {
  max-width: 1120px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.aboutSubScriptionsHeaderTextStyle {
  margin: auto;
  max-width: 738px;
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 40px;
  line-height: 52px;
  color: #000000;
}
.aboutSubScriptionsDescTextStyle {
  margin: auto;
  max-width: 738px;
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #202020;
}
.plansMainContainerStyle {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 16px;
}
.planInsideRedContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background-color: #fa13631a;
  border-radius: 16px;
  align-items: flex-start;
}
.planTimeDetailsContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.limitContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.checkImgWrapperStyle {
  width: 40px;
  height: 40px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.planTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #202020;
  text-align: left;
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 1279px) {
}

@media screen and (max-width: 1023px) {
  .aboutSubscriptionsMainConatinerStyle {
    gap: 56px;
  }
  .aboutSubscriptionsHeaderContainerStyle {
    max-width: 1120px;
    gap: 12px;
  }
  .aboutSubScriptionsHeaderTextStyle {
    font-size: 36px;
    line-height: 44px;
  }
  .aboutSubScriptionsDescTextStyle {
    max-width: 668px;
  }
  .plansMainContainerStyle {
    grid-template-columns: auto auto;
    gap: 12px;
  }
}
@media screen and (max-width: 867px) {
  .aboutSubscriptionsMainConatinerStyle {
    gap: 48px;
  }
  .aboutSubScriptionsHeaderTextStyle {
    font-size: 30px;
    line-height: 40px;
  }
  .aboutSubScriptionsDescTextStyle {
    max-width: 100%;
  }
  .plansMainContainerStyle {
    grid-template-columns: auto;
    gap: 12px;
  }
  .planInsideRedContainerStyle {
    width: 452px;
    margin: auto;
  }
}

@media screen and (max-width: 576px) {
  .aboutSubscriptionsMainConatinerStyle {
    gap: 32px;
  }
  .aboutSubScriptionsHeaderTextStyle {
    text-align: left;
  }
  .aboutSubScriptionsDescTextStyle {
    text-align: left;
  }
  .planInsideRedContainerStyle {
    width: 100%;
  }
}
