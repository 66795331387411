.simpleContainerStyle {
  max-width: 1440px;
  margin: auto;
  padding: 100px 160px;
}
.simpleHeaderContainerStyle {
  align-items: center;
  justify-content: center;
}
.simpleHeaderTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 40px;
  line-height: 52px;
  color: #000000;
}
.simpleDescriptionTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #000000;
  margin-top: 16px;
}
.flowContainerStyle {
  padding: 36px 0px 100px 0px;
}
.flowSection1Style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 64px;
  margin-top: 64px;
}
.flowSection2Style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 64px;
  margin-top: 64px;
}
.foundiFlowContentWrapperStyle {
  max-width: 704px;
  text-align: left;
}
.foundiFlowtitleTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 16px;
}
.foundiFlowDescTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #202020;
}
.imgWrapperStyle {
  max-width: 352px;
  max-height: 352px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.playStoreContainerStyle {
  margin: auto;
  width: 364.31px;
  display: flex;
  gap: 33px;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}
.appStoreWrapperStyle {
  width: 166.15px;
  height: 48px;
  cursor: pointer;
}
.productsMainContainerStyle {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 16px;
  margin-top: 64px;
}
.productContainerStyle {
  background-color: #f2f2f2;
  padding: 32px;
  border-radius: 16px;
  transform-origin: top;
  transition: 0.4s ease-in-out;
}
.productContainerStyle:hover {
  transform-origin: top;
  transform: scaleY(1.05);
}

.productContainerStyle:hover .productImgWrapperStyle {
  transform: scale(1.1);
}
.productImgWrapperStyle {
  max-width: 298.67px;
  max-height: 224px;
  border-radius: 16px;
  transition: ease-in 0.25s;
}

.productNameTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #202020;
  margin-top: 32px;
}
.productPriceStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: #202020;
  margin-top: 8px;
}
.browseAllProductsAnchorStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 64px;
  margin-bottom: 100px;
}
.browseAllProductsStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #1373fb;
  border-bottom: 2px solid #1373fb;
  padding-bottom: 3px;
  cursor: pointer;
}
.questionsContainerStyle {
  padding: 64px 140px 32px 140px;
}
.questionWrapperStyle {
  margin-bottom: 40px;
}
.questionTextwrapperStyle {
  background-color: #f2f2f2;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: space-between;
}
.questionTextStyle {
  max-width: 684px;
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  text-align: left;
}
.arrowImgWrapperStyle {
  width: 28px;
  min-width: 28px;
  height: 28px;
  cursor: pointer;
}
.answerTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #202020;
  padding: 0px 32px;
  text-align: left;
  margin-top: 16px;
}
.letsTalkWrapperStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.letsTalkTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #1373fb;
}
@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
  .simpleContainerStyle {
    padding: 100px 128px;
  }
  .simpleDescriptionTextStyle {
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }
  .foundiFlowDescTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .foundiFlowContentWrapperStyle {
    max-width: 608px;
  }
  .playStoreContainerStyle {
    gap: 33px;
    margin-bottom: 101px;
  }
  .appStoreWrapperStyle {
    width: 164.31px;
    height: 46.19px;
  }
  .productNameTextStyle {
    font-size: 16px;
    line-height: 24px;
    margin-top: 32px;
  }
  .productImgWrapperStyle {
    max-width: 266.67px;
    max-height: 224px;
  }

  .browseAllProductsStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .questionsContainerStyle {
    padding: 64px 92px 32px 92px;
  }
}
@media screen and (max-width: 1279px) {
  .simpleContainerStyle {
    padding: 100px 80px;
  }
  .imgWrapperStyle {
    max-width: 320px;
    max-height: 320px;
  }
  .foundiFlowContentWrapperStyle {
    max-width: 576px;
  }
  .productContainerStyle {
    padding: 32px 24px;
  }
  .productImgWrapperStyle {
    max-width: 261.33px;
    max-height: 224px;
  }
  .questionsContainerStyle {
    padding: 64px 100px 32px 100px;
  }
  .questionTextStyle {
    max-width: 604px;
  }
}

@media screen and (max-width: 1023px) {
  .simpleContainerStyle {
    padding: 80px 60px;
  }
  .simpleHeaderTextStyle {
    font-size: 36px;
    line-height: 44px;
  }
  .simpleDescriptionTextStyle {
    margin-top: 12px;
  }
  .foundiFlowtitleTextStyle {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 12px;
  }
  .imgWrapperStyle {
    max-width: 280px;
    max-height: 280px;
  }
  .flowSection1Style {
    gap: 56px;
    margin-top: 56px;
  }
  .flowSection2Style {
    gap: 56px;
    margin-top: 56px;
  }
  .foundiFlowContentWrapperStyle {
    max-width: 484px;
  }
  .flowContainerStyle {
    padding: 0px 0px 80px 0px;
  }
  .playStoreContainerStyle {
    width: 300px;
    gap: 24px;
    margin-bottom: 80px;
  }
  .appStoreWrapperStyle {
    width: 138.46px;
    height: 40px;
  }
  .productsMainContainerStyle {
    gap: 12px;
    margin-top: 56px;
  }
  .productNameTextStyle {
    margin-top: 24px;
  }
  .productContainerStyle {
    padding: 24px;
  }
  .productImgWrapperStyle {
    max-width: 217.33px;
    max-height: 192px;
  }
  .browseAllProductsAnchorStyle {
    margin-top: 56px;
    margin-bottom: 80px;
  }
  .questionsContainerStyle {
    padding: 56px 0px 24px 0px;
  }
  .questionWrapperStyle {
    margin-bottom: 28px;
  }
  .questionTextwrapperStyle {
    padding: 24px;
  }
  .questionTextStyle {
    max-width: 680px;
  }
}
@media screen and (max-width: 867px) {
  .simpleContainerStyle {
    padding: 80px 32px;
  }
  .simpleHeaderTextStyle {
    font-size: 30px;
    line-height: 40px;
  }
  .foundiFlowtitleTextStyle {
    font-size: 24px;
    line-height: 34px;
  }
  .foundiFlowDescTextStyle {
    text-align: center;
  }
  .imgWrapperStyle {
    max-width: 452px;
    max-height: 452px;
  }
  .flowSection1Style {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-top: 64px;
  }
  .flowSection2Style {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-top: 64px;
  }
  .foundiFlowContentWrapperStyle {
    max-width: 452px;
    text-align: center;
  }
  .productsMainContainerStyle {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 48px;
  }
  .productImgWrapperStyle {
    max-width: 404px;
    max-height: 248px;
  }
  .browseAllProductsAnchorStyle {
    margin-top: 48px;
    margin-bottom: 80px;
  }
  .questionsContainerStyle {
    padding: 48px 0px 16px 0px;
  }
  .questionWrapperStyle {
    margin-bottom: 32px;
  }
  .questionTextStyle {
    max-width: 496px;
  }
}

@media screen and (max-width: 559px) {
  .simpleContainerStyle {
    padding: 80px 24px;
  }
  .simpleHeaderContainerStyle {
    text-align: left;
  }
  .imgWrapperStyle {
    max-width: 366px;
    max-height: 366px;
  }
  .foundiFlowContentWrapperStyle {
    max-width: 366px;
  }
  .foundiFlowContentWrapperStyle {
    max-width: 452px;
    text-align: left;
  }
  .foundiFlowDescTextStyle {
    text-align: left;
  }
  .productsMainContainerStyle {
    margin-top: 32px;
  }
  .playStoreContainerStyle {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 80px;
  }
  .appStoreWrapperStyle {
    width: 166.15px;
    height: 48px;
  }
  .productImgWrapperStyle {
    max-width: 318px;
    max-height: 248px;
  }
  .browseAllProductsAnchorStyle {
    margin-top: 32px;
    margin-bottom: 80px;
  }
  .questionsContainerStyle {
    padding: 32px 0px 0px 0px;
  }
  .questionTextStyle {
    max-width: 266px;
  }
}
