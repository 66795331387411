.customerLikedSectionContainerStyle {
  max-width: 1440px;
  margin: auto;
  padding: 64px 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.headerTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 40px;
  line-height: 52px;
  color: #000000;
  margin-bottom: 64px;
}
.productsListContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 64px;
}

.imgWrapperStyle {
  width: 298px;
  height: 224px;
  border-radius: 16px;
  transition: ease-in 0.25s;
}
.productWrapperStyle:hover .imgWrapperStyle {
  transform: scale(1.05);
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.productWrapperStyle {
  background-color: #f2f2f2;
  padding: 32px;
  border-radius: 16px;
  transform-origin: top;
  transition: 0.4s ease-in-out;
}
.productWrapperStyle:hover {
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
  transform-origin: top;
  transform: scaleY(1.05);
}
.productNameTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #202020;
  margin-bottom: 8px;
}
.productDiscountPriceTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.productOriginalPriceTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #202020;
}
.browseAllProductsTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #1373fb;
  text-decoration: underline;
}
@media screen and (max-width: 1440px) {
  .productNameTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .browseAllProductsTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1366px) {
  .customerLikedSectionContainerStyle {
    padding: 64px 128px;
  }
  .imgWrapperStyle {
    width: 266px;
    height: 224px;
  }
}
@media screen and (max-width: 1279px) {
  .customerLikedSectionContainerStyle {
    padding: 64px 80px;
  }
  .imgWrapperStyle {
    width: 245px;
    height: 224px;
  }
}

@media screen and (max-width: 1023px) {
  .customerLikedSectionContainerStyle {
    padding: 44px 60px;
  }
  .headerTextStyle {
    font-size: 36px;
    line-height: 44px;

    margin-bottom: 56px;
  }
  .productsListContainerStyle {
    flex-direction: column;
    gap: 12px;
    margin-bottom: 56px;
  }
  .imgWrapperStyle {
    width: 404px;
    height: 224px;
  }

  .productWrapperStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
  }
}
@media screen and (max-width: 867px) {
  .customerLikedSectionContainerStyle {
    padding: 44px 32px;
  }
  .headerTextStyle {
    font-size: 30px;
    line-height: 40px;

    margin-bottom: 48px;
  }
  .productsListContainerStyle {
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 576px) {
  .customerLikedSectionContainerStyle {
    padding: 44px 24px;
  }
  .headerTextStyle {
    margin-bottom: 32px;
    text-align: left;
  }
  .productsListContainerStyle {
    margin-bottom: 32px;
  }
  .imgWrapperStyle {
    width: 100%;
    height: 224px;
  }
}
