@font-face {
  font-family: "Euclid-Circular-B-Bold";
  src: url("./assets/Fonts/Euclid-Circular-B-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Euclid-Circular-B-SemiBold";
  src: url("./assets/Fonts/Euclid-Circular-B-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Euclid-Circular-B-Medium";
  src: url("./assets/Fonts/Euclid-Circular-B-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Euclid-Circular-B-Regular";
  src: url("./assets/Fonts/Euclid-Circular-B-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Euclid-Circular-B-Light";
  src: url("./assets/Fonts/Euclid-Circular-B-Light.ttf");
  font-weight: 300;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
