.checkoutPageMainContainerStyle {
  max-width: 1440px;
  margin: auto;
  padding: 33px 80px 64px;
  display: flex;
  flex-direction: column;
  gap: 65px;
}
.closeContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.closeIconWrapperStyle {
  width: 20px;
  height: 20px;
}

.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.checkoutTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}
.checkOutGridContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 64px;
}
.checkOutPageInsideContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.checkoutPageInputContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.checkoutHeaderContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
  padding: 24px;
  border-radius: 16px;
}
.checkIconWrapperStyle {
  width: 24px;
  height: 24px;
  border-radius: 16px;
  background-color: #c4c4c4;
}
.checkOutHeaderInsideContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.contactInputContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.contactDetailsInputContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.contactDetailsTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.orderSummeryStyle {
  min-width: 452px;
  height: fit-content;
  border: 1px solid #d0d0d0;
  border-radius: 16px;
}
.orderedProductsSummeryContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
}
.orderSummeryTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #202020;
  text-align: left;
}
.noOfOrderedProductsContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.orderProductContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.orderProductImgWrapperStyle {
  width: 106px;
  height: 106px;
  border-radius: 8px;
  background-color: #f2f2f2;
  padding: 4px;
}
.orderedProductRightContainerStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.orderProductPricesContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.orderProductTagsTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  text-align: left;
}
.orderProductPriceTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #202020;
  text-align: left;
}
.orderProductDetailsContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.addMinusContainerSTyle {
  display: flex;
  flex-direction: row;
  gap: 13px;
}
.productNumberStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #202020;
  text-align: center;
  align-self: center;
}
.addIconWrapperStyle {
  width: 32px;
  height: 32px;
  background-color: #f2f2f2;
  border: 1px solid #ebebeb;
  padding: 6px;
  border-radius: 8px;
}
.iconWrapperStyle {
  width: 20px;
  height: 20px;
}
.dividerStyle {
  width: 100%;
  border-bottom: 1px solid #ebebeb;
}
.costAndPayContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.costListContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.productPriceContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.fillAddressTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: #505050;
}
.priceSubHeadTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 24px;
  color: #202020;
}
.costTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.totalPriceContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.totalTextStyle {
  display: flex;
  flex-direction: column;
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  align-items: flex-start;
}
.totalPriceTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.withoutShippingtextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 15px;
  line-height: 22px;
  color: #505050;
}
.payBtnStyle {
  background-color: #1373fb80;
  color: #ffffff;
  border: none;
  padding: 12px;
  border-radius: 28px;
  font-size: 17px;
  line-height: 24px;
}
/* modal Container Styles */
.modalContainerStyle {
  max-width: 588px;
  margin: auto auto auto auto;
  padding: 32px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: #ffffff;
}
.orderPlacedImgWrapperStyle {
  width: 123px;
  height: 120px;
}
.modalInsideContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.textContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.modalHeadertextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.modalDescTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #202020;
}
.btnContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.trackOrderBtnStyle {
  width: 100%;
  background-color: #1373fb;
  color: #ffffff;
  border: none;
  padding: 12px 0;
  border-radius: 28px;
  font-size: 17px;
  line-height: 24px;
}
.doitLaterBtnStyle {
  width: 100%;
  border: 1px solid #000000;
  background-color: #ffffff;
  color: #000000;
  padding: 12px 0;
  border-radius: 28px;
  font-size: 17px;
  line-height: 24px;
}

/* modal Container Styles end */
@media screen and (max-width: 1440px) {
  .fillAddressTextStyle {
    font-size: 14px;
    line-height: 20px;
  }
  .priceSubHeadTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .withoutShippingtextStyle {
    font-family: "Euclid-Circular-B-Light", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #505050;
  }
  .payBtnStyle {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 1279px) {
  .orderSummeryStyle {
    min-width: 420px;
  }
}

@media screen and (max-width: 1023px) {
  .checkoutPageMainContainerStyle {
    padding: 25px 60px 40px;
    gap: 57px;
  }
  .checkOutGridContainerStyle {
    flex-direction: column;
    gap: 56px;
    width: 560px;
    margin: auto;
  }
  .orderedProductsSummeryContainerStyle {
    gap: 24px;
    padding: 24px;
  }
}
@media screen and (max-width: 867px) {
  .checkoutPageMainContainerStyle {
    padding: 25px 32px 40px;
    gap: 49px;
  }
  .checkOutGridContainerStyle {
    gap: 48px;
    max-width: 100%;
  }
  .checkOutPageInsideContainerStyle {
    gap: 32px;
  }
  .checkoutPageInputContainerStyle {
    gap: 24px;
  }
  .checkoutHeaderContainerStyle {
    padding: 16px;
  }
  .contactInputContainerStyle {
    gap: 16px;
  }
}

@media screen and (max-width: 576px) {
  .checkoutPageMainContainerStyle {
    padding: 25px 24px 40px;
    gap: 33px;
  }
  .checkOutGridContainerStyle {
    max-width: 100%;
    gap: 32px;
  }
  .contactDetailsInputContainerStyle {
    flex-direction: column;
  }
  .orderSummeryStyle {
    min-width: 100%;
  }
  .modalContainerStyle {
    padding: 24px;
    gap: 24px;
    border-radius: 16px 16px 0 0;
  }
  .orderPlacedImgWrapperStyle {
    width: 103px;
    height: 100px;
  }
}
