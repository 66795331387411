.reviewContainerStyle {
  max-width: 1440px;
  margin: auto;
  padding: 36px 160px;
}
.reviewHeaderTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 40px;
  line-height: 52px;
  color: #000000;
  margin-bottom: 16px;
}
.mostRecentContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
}
.mostRecentTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: #202020;
}
.imgWrapperStyle {
  width: 10px;
  height: 10px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.reviewListContainerStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 64px 140px;
}
.reviewInsideContainerStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #f2f2f2;
  border-radius: 16px;
  padding: 32px;
}
.reviewDescTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #202020;
  margin-bottom: 24px;
  margin-top: 16px;
  text-align: left;
}
.reviewNameAndDateContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.reviewerNameTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #000000;
}
.reviewSentDateTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 15px;
  line-height: 22px;
  color: #505050;
}
.viewMoreTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #1373fb;
  text-align: center;
  text-decoration: underline;
}

@media screen and (max-width: 1440px) {
  .mostRecentTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .reviewDescTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .reviewerNameTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .reviewSentDateTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .viewMoreTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1366px) {
  .reviewContainerStyle {
    padding: 36px 128px;
  }
  .reviewListContainerStyle {
    padding: 64px 92px;
  }
}
@media screen and (max-width: 1279px) {
  .reviewContainerStyle {
    padding: 36px 80px;
  }
  .reviewListContainerStyle {
    padding: 64px 60px;
  }
}

@media screen and (max-width: 1023px) {
  .reviewContainerStyle {
    padding: 24px 60px;
  }
  .reviewHeaderTextStyle {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 12px;
  }
  .reviewListContainerStyle {
    padding: 56px 0px;
  }
  .reviewInsideContainerStyle {
    padding: 24px;
  }
  .reviewDescTextStyle {
    margin-bottom: 16px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 867px) {
  .reviewContainerStyle {
    padding: 48px 32px;
  }
  .reviewHeaderTextStyle {
    font-size: 30px;
    line-height: 40px;
  }
  .reviewListContainerStyle {
    padding: 48px 0px;
  }
}

@media screen and (max-width: 576px) {
  .reviewContainerStyle {
    padding: 56px 24px;
  }
  .reviewHeaderTextStyle {
    text-align: left;
  }
  .mostRecentContainerStyle {
    justify-content: flex-start;
  }
  .reviewListContainerStyle {
    padding: 32px 0px;
  }
}
