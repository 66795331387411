.heroMainContainerStyle {
  background-color: #ffffff;
}
.heroinsideContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.headerContentStyle {
  display: grid;
  grid-template-columns: auto auto;
  align-items: flex-start;
  gap: 80px;
}
.headerTextStyle {
  max-width: 452px;
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 40px;
  line-height: 52px;
  color: #000000;
  text-align: left;
}
.headerDescTextStyle {
  max-width: 588px;
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #202020;
  text-align: left;
}
.heroImgWrapperStyle {
  max-width: 1120px;
  height: 603px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}
.aboutAppHeaderContainerStyle {
  max-width: 738px;
  margin: auto;
}
.aboutAppHeaderTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 40px;
  line-height: 52px;
  color: #808080;
}
.boldHeaderTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 40px;
  line-height: 52px;
  color: #202020;
}
.aboutAppDescContainerStyle {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 40px;
}
.aboutAppDescInnerContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.aboutAppDescHeaderTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 40px;
  line-height: 52px;
  color: #202020;
  text-align: left;
}
.aboutAppDescHeaderContentTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #202020;
  text-align: left;
}
@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
  .heroImgWrapperStyle {
    height: 603px;
  }
}
@media screen and (max-width: 1279px) {
  .heroImgWrapperStyle {
    height: 496px;
  }
}

@media screen and (max-width: 1023px) {
  .heroinsideContainerStyle {
    gap: 56px;
  }
  .headerContentStyle {
    grid-template-columns: auto;
    gap: 12px;
    justify-content: center;
  }
  .headerTextStyle {
    max-width: 560px;
    font-size: 36px;
    line-height: 44px;
  }
  .headerDescTextStyle {
    max-width: 560px;
  }
  .heroImgWrapperStyle {
    height: 452px;
  }
  .aboutAppHeaderContainerStyle {
    max-width: 668px;
  }
  .aboutAppHeaderTextStyle {
    font-size: 36px;
    line-height: 44px;
  }
  .boldHeaderTextStyle {
    font-size: 36px;
    line-height: 44px;
  }
  .aboutAppDescContainerStyle {
    grid-template-columns: auto auto;
  }
  .aboutAppDescInnerContainerStyle {
    gap: 12px;
  }
  .aboutAppDescHeaderTextStyle {
    font-size: 36px;
    line-height: 44px;
  }
}
@media screen and (max-width: 867px) {
  .heroinsideContainerStyle {
    gap: 48px;
  }
  .headerTextStyle {
    max-width: 100%;
    font-size: 30px;
    line-height: 40px;
  }
  .headerDescTextStyle {
    max-width: 100%;
  }
  .heroImgWrapperStyle {
    height: 380px;
  }
  .aboutAppHeaderContainerStyle {
    max-width: 604px;
  }
  .aboutAppHeaderTextStyle {
    font-size: 30px;
    line-height: 40px;
  }
  .boldHeaderTextStyle {
    font-size: 30px;
    line-height: 40px;
  }
  .aboutAppDescHeaderTextStyle {
    font-size: 30px;
    line-height: 40px;
  }
}

@media screen and (max-width: 576px) {
  .heroinsideContainerStyle {
    gap: 32px;
  }
  .heroImgWrapperStyle {
    height: 366px;
  }
  .aboutAppHeaderContainerStyle {
    max-width: 100%;
  }
  .aboutAppHeaderTextStyle {
    text-align: left;
  }
  .aboutAppDescContainerStyle {
    grid-template-columns: auto;
    gap: 32px;
  }
}
