.productDetailsContainerStyle {
  max-width: 1440px;
  margin: auto;
  padding: 64px 160px;
}
.productDetailsInnerContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 64px;
}
.imgContainerStyle {
  padding: 32px;
  background-color: #f2f2f2;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.imgWrapperStyle {
  width: 476px;
  height: 411px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.productNameTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  text-align: left;
  margin-bottom: 16px;
}
.productDescriptionTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #202020;
  text-align: left;
  margin-bottom: 32px;
}
.productDiscountPriceTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  text-align: left;
  margin-bottom: 32px;
}
.productOriginalPriceTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #505050;
}
.btnContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
}
.whiteButtonStyle {
  width: 100%;
  height: 48px;
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 28px;

  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 17px;
  line-height: 24px;
}
.blueButtonStyle {
  width: 100%;
  height: 48px;
  background-color: #1373fb;
  border: 1px solid #1373fb;
  border-radius: 28px;
  color: #ffffff;

  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 17px;
  line-height: 24px;
}
.dotsWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.dotsStyle {
  width: 8px;
  height: 8px;
  border: 1px solid #000000;
  border-radius: 4px;
}
.dotsSelectedStyle {
  width: 8px;
  height: 8px;
  border: 1px solid #000000;
  background-color: #000000;
  border-radius: 4px;
}
.cartNumberContainerStyle {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border-radius: 28px;
  background-color: #ffffff;
  border: 1px solid #000000;
}
.iconWrapperStyle {
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 1440px) {
  .whiteButtonStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .blueButtonStyle {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1366px) {
  .productDetailsContainerStyle {
    padding: 64px 80px;
  }
  .productDescriptionTextStyle {
    font-size: 16px;
    line-height: 24px;

    margin-bottom: 32px;
  }
}
@media screen and (max-width: 1279px) {
  .whiteButtonStyle {
    /* width: 214px; */
    height: 48px;
  }
  .blueButtonStyle {
    /* width: 214px; */
    height: 48px;
  }
  .cartNumberContainerStyle {
    /* width: 214px; */
    height: 48px;
  }
  .imgWrapperStyle {
    max-width: 388px;
    max-height: 388px;
  }
}

@media screen and (max-width: 1023px) {
  .productDetailsContainerStyle {
    padding: 56px 60px;
  }
  .productDetailsInnerContainerStyle {
    gap: 56px;
  }
  .productNameTextStyle {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 12px;
  }
  .productDescriptionTextStyle {
    margin-bottom: 24px;
  }
  .productDiscountPriceTextStyle {
    margin-bottom: 24px;
  }
  .btnContainerStyle {
    gap: 12px;
  }
  .whiteButtonStyle {
    /* width: 186px; */
    height: 48px;
  }
  .blueButtonStyle {
    /* width: 186px; */
    height: 48px;
  }
  .cartNumberContainerStyle {
    /* width: 186px; */
    height: 48px;
  }
  .imgWrapperStyle {
    max-width: 332px;
    max-height: 332px;
  }
}
@media screen and (max-width: 867px) {
  .productDetailsContainerStyle {
    padding: 32px;
  }
  .productDetailsInnerContainerStyle {
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: space-around;
  }
  .productNameTextStyle {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
  }
  .productDescriptionTextStyle {
    text-align: center;
  }
  .productDiscountPriceTextStyle {
    text-align: center;
  }

  .whiteButtonStyle {
    width: 100%;
    height: 48px;
  }
  .blueButtonStyle {
    width: 100%;
    height: 48px;
  }
  .cartNumberContainerStyle {
    width: 100%;
    height: 48px;
  }
  .imgWrapperStyle {
    max-width: 556px;
    max-height: 332px;
  }
}

@media screen and (max-width: 576px) {
  .productDetailsContainerStyle {
    padding: 24px;
  }
  .productNameTextStyle {
    text-align: left;
  }
  .productDescriptionTextStyle {
    text-align: left;
  }
  .productDiscountPriceTextStyle {
    text-align: left;
  }
  .btnContainerStyle {
    flex-direction: column;
  }
  .whiteButtonStyle {
    max-width: 100%;
    height: 48px;
  }
  .blueButtonStyle {
    max-width: 100%;
    height: 48px;
  }
  .cartNumberContainerStyle {
    max-width: 100%;
    height: 48px;
  }
  .imgWrapperStyle {
    width: 100%;
    height: 100%;
  }
}
