.buttonStyle {
  display: flex;
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 14px;
  line-height: 22px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}
.imgWrapperStyle {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
