.privacyPolicyMainContainerStyle {
  max-width: 1440px;
  margin: auto;
  padding: 64px 160px 100px;
  display: flex;
  flex-direction: column;
  gap: 100px;
  align-items: center;
}
.privacyHeroSectionContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 64px;
}
.securityImgOuterWrapperStyle {
  background-color: #f2f2f2;
  border-radius: 16px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.securityImgWrapperStyle {
  min-width: 360px;
  height: 428px;
  border-radius: 16px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}
.privacyContentContainerStyle {
  max-width: 696px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}
.privacyHeaderTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 40px;
  line-height: 52px;
  color: #000000;
  text-align: left;
}
.privacyDescTextContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.privacyDescTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #202020;
  text-align: start;
}
.secondSectionContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.privacyPolicyHeaderSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.privacyPolicyHeaderTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 40px;
  line-height: 52px;
  color: #000000;
}
.lastUpdatedTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #1373fb;
}
.lastUpdatedDateTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #202020;
}
.privacySecondSectionContentContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.secondSectionHeaderTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  text-align: left;
}
.anchorLinkStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #1373fb;
  border-bottom: 1px solid #1373fb;
}
.browserTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #1373fb;
}
@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
  .privacyPolicyMainContainerStyle {
    padding: 64px 128px 100px;
  }
  .securityImgWrapperStyle {
    height: 452px;
  }
}
@media screen and (max-width: 1279px) {
  .privacyPolicyMainContainerStyle {
    padding: 64px 80px 100px;
  }
  .securityImgWrapperStyle {
    min-width: 320px;
  }
}

@media screen and (max-width: 1023px) {
  .privacyPolicyMainContainerStyle {
    padding: 56px 60px 80px;
  }
  .privacyHeroSectionContainerStyle {
    gap: 56px;
  }
  .securityImgWrapperStyle {
    min-width: 260px;
    height: 260px;
  }
  .privacyContentContainerStyle {
    gap: 12px;
  }
  .privacyHeaderTextStyle {
    font-size: 36px;
    line-height: 44px;
  }
  .privacyDescTextContainerStyle {
    gap: 16px;
  }
  .privacyPolicyHeaderTextStyle {
    font-size: 36px;
    line-height: 44px;
  }
}
@media screen and (max-width: 867px) {
  .privacyPolicyMainContainerStyle {
    padding: 48px 32px 80px;
  }
  .privacyHeroSectionContainerStyle {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }
  .securityImgWrapperStyle {
    width: 216px;
    height: 260px;
  }
  .privacyContentContainerStyle {
    max-width: 100%;
  }
  .privacyHeaderTextStyle {
    font-size: 30px;
    line-height: 40px;
  }
  .privacyPolicyHeaderTextStyle {
    font-size: 30px;
    line-height: 40px;
  }
}

@media screen and (max-width: 576px) {
  .privacyPolicyMainContainerStyle {
    padding: 32px 24px 80px;
  }
  .privacyHeroSectionContainerStyle {
    gap: 32px;
  }
  .securityImgWrapperStyle {
    min-width: 366;
    height: 260px;
  }
}
