.orderMainContainerStyle {
  background-color: #f2f2f2;
}
.orderMainInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
  padding: 33px 80px 64px;
  display: flex;
  flex-direction: column;
  gap: 33px;
}
.navContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.navHeaderTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 15px;
  line-height: 22px;
  color: #202020;
}

.closeIconWrapperStyle {
  width: 20px;
  height: 20px;
}

.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.orderedHistoryDetailsStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.recentOrdersContainerStyle {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.orderDetailsContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.orderDetailsWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
}
.orderNoTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.orderPriceTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.orderedDateLabelTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 24px;
  color: #505050;
  text-align: left;
}
.orderedDateTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 17px;
  line-height: 24px;
  color: #505050;
}
.orderDeliveryTimeContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.orderDeliveryTimeLabelStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 24px;
  color: #505050;
}
.orderDeliveryTimeTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 17px;
  line-height: 24px;
  color: #1373fb;
}
.orderDetailsRightContainerStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.orderredInvoiceTextStyle {
  width: fit-content;
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 17px;
  line-height: 24px;
  color: #505050;
  border-bottom: 1px solid #505050;
}
.productImgWrapperstyle {
  width: 112px;
  height: 112px;
  background-color: #f2f2f2;
  border-radius: 8px;
  padding: 4px;
}
.productsMainContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.noOfProductsContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.orderedProductDetailsContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.orderedProductContentWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
}
.productPriceContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
}
.productTagsTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 17px;
  line-height: 24px;
  color: #000000;
}
.productPriceTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 15px;
  line-height: 22px;
  color: #202020;
}
.reviewBtn1Styles {
  background-color: #f2f2f2;
  border-radius: 20px;
  width: 149px;
  height: 34px;
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 15px;
  line-height: 22px;
  color: #202020;
  border: none;
}
.reviewBtn2Styles {
  display: none;
  background-color: #f2f2f2;
  border-radius: 20px;
  width: 149px;
  height: 34px;
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 15px;
  line-height: 22px;
  color: #202020;
  border: none;
}
.btnContainerStyles {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: end;
}
.trackBtnStyles {
  background-color: #1373fb;
  width: 260px;
  height: 48px;
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 17px;
  line-height: 24px;
  color: #ffffff;
  border: none;
  border-radius: 24px;
}
.cancelBtnStyles {
  background-color: #ffffff;
  width: 260px;
  height: 48px;
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 17px;
  line-height: 24px;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 24px;
}
.borderStyle {
  display: none;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
}
/* review modal styles  */
.reviewModalContainerStyle {
  width: 452px;
  background-color: #ffffff;
  border-radius: 16px;
}
.reviewModalTopContainerStyle {
  width: 100%;
  background-color: #f2f2f2;
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalImgWrapperStyles {
  width: 160px;
  height: 160px;
}
.reviewModalBottomContainerStyle {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.reviewModalHeaderTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.reviewRatingContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.textareaStyle {
  width: 100%;
  height: 96px;
  padding: 12px;
  border: 1px solid #d0d0d0;
  border-radius: 8px;
  resize: none;
}
.reviewModalBtnContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.modalSubmitBtnStyles {
  background-color: #1373fb80;
  width: 100%;
  height: 48px;
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  border: none;
  border-radius: 28px;
}
.modalRemindLaterBtnStyles {
  background-color: #ffffff;
  width: 100%;
  height: 48px;
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 28px;
}
/* review modal styles end */
/* tracking modal styles  */
.trackingModalContainerStyle {
  width: 558px;
  padding: 32px;
  background-color: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.trackingModalHeaderContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.trackingModalHeaderTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.trackingModalEsitmContainerStyle {
  background-color: #f2f2f2;
  border-radius: 16px;
  padding: 16px;
}
.trackingModalEstimTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #202020;
}
.trackingModalListContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
}
.checkIconMainWrapperStyle {
  width: 48px;
  height: 48px;
  background-color: #1373fb;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkIconWrapperStyle {
  width: 24px;
  height: 24px;
}
.trackDataTextWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.trackDataTextHeaderStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.trackDataTextTimeStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #202020;
}
.trackingModalTimeContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
/* tracking modal styles end */
@media screen and (max-width: 1440px) {
  .navHeaderTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .orderedDateLabelTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .orderedDateTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .orderDeliveryTimeContainerStyle {
    gap: 0px;
  }
  .orderDeliveryTimeLabelStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .orderDeliveryTimeTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .orderredInvoiceTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .productTagsTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .productPriceTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .reviewBtn1Styles {
    font-size: 14px;
  }
  .reviewBtn2Styles {
    font-size: 14px;
  }
  .trackBtnStyles {
    font-size: 16px;
  }
  .cancelBtnStyles {
    font-size: 16px;
  }
}

@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 1279px) {
}

@media screen and (max-width: 1023px) {
  .orderMainInsideContainerStyle {
    padding: 33px 60px 64px;
  }
}
@media screen and (max-width: 867px) {
  .orderMainInsideContainerStyle {
    padding: 17px 32px 40px;
    gap: 25px;
  }
  .orderDetailsContainerStyle {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .orderDetailsRightContainerStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .productsMainContainerStyle {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .trackBtnStyles {
    width: 100%;
  }
  .cancelBtnStyles {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .orderMainInsideContainerStyle {
    padding: 17px 24px 40px;
  }
  .recentOrdersContainerStyle {
    padding: 24px;
    gap: 32px;
  }
  .productImgWrapperstyle {
    width: 80px;
    height: 80px;
  }
  .orderedProductDetailsContainerStyle {
    gap: 16px;
  }
  .borderStyle {
    display: block;
    width: 100%;
    border-bottom: 1px solid #ebebeb;
  }
  .reviewBtn1Styles {
    display: none;
  }
  .reviewBtn2Styles {
    display: block;
    width: 100%;
  }
  .orderedProductContentWrapperStyle {
    justify-content: end;
  }
  .reviewModalContainerStyle {
    width: 100%;
    border-radius: 16px 16px 0 0;
  }
  .reviewModalBottomContainerStyle {
    padding: 16px 24px;
  }
  .reviewModalBtnContainerStyle {
    display: flex;
    flex-direction: column;
  }
  .trackingModalContainerStyle {
    width: 100%;
    padding: 24px;
    border-radius: 16px 16px 0 0;
  }
}
