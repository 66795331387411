.eventScreenFitContainerStyle {
  background: rgba(0, 0, 0, 0.5);
}
.eventOutsideContainerStyle {
  backdrop-filter: blur(40px);
}
.eventInsideContainerStyle {
  position: relative;
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 80px;
  height: auto;
}
.eventHeaderTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 40px;
  line-height: 52px;
  color: #ffffff;
  margin-bottom: 16px;
}
.eventDescriptionTextStyle {
  max-width: 674px;
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #ebebeb;
  margin-bottom: 64px;
}
.linkContainerStyle {
  display: none;
}
.imgWrapperStyle:hover .linkContainerStyle {
  position: absolute;
  top: 50%;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 24px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  z-index: 10;
}
.arrowImgWrapperStyle {
  width: 24px;
  height: 24px;
}
.linkTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.imageButtonsContainerStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 175px;
}
.imageButtonsTopContainerStyle {
  display: none;
}
.imgWrapperStyle {
  position: relative;
  max-width: 850px;
  max-height: 452px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* animation: anim ease-in 1s; */
}
.imgWrapperStyle2 {
  max-width: 850px;
  max-height: 452px;
  border-radius: 16px;
  overflow: hidden;
  /* animation: anim2 ease-in 1s; */
}
/* @keyframes anim {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes anim2 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
} */
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: ease-in 0.25s;
}
.imgStyle:hover {
  transform: scale(1.05);
}
.leftArrowImgWrapperStyle {
  min-width: 40px;
  height: 40px;
}
.rightArrowImgWrapperStyle {
  min-width: 40px;
  height: 40px;
}
.leftArrowTopImgWrapperStyle {
  min-width: 40px;
  height: 40px;
}
.rightArrowTopImgWrapperStyle {
  min-width: 40px;
  height: 40px;
}
@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
  .eventDescriptionTextStyle {
    max-width: 674px;
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1279px) {
  .leftArrowImgWrapperStyle {
    display: none;
  }
  .rightArrowImgWrapperStyle {
    display: none;
  }
  .eventDescriptionTextStyle {
    margin-bottom: 32px;
  }
  .imageButtonsTopContainerStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 1023px) {
  .eventHeaderTextStyle {
    font-size: 36px;
    line-height: 44px;

    margin-bottom: 12px;
  }
  .eventDescriptionTextStyle {
    max-width: 560px;
  }
  .imgWrapperStyle {
    max-width: 720px;
    max-height: 360px;
  }
  .eventInsideContainerStyle {
    height: auto;
  }
  .eventInsideContainer2Style {
    height: auto;
  }
}
@media screen and (max-width: 867px) {
  .eventHeaderTextStyle {
    font-size: 30px;
    line-height: 40px;
  }
  .eventDescriptionTextStyle {
    max-width: 452px;
  }
  .imgWrapperStyle {
    max-width: 452px;
    max-height: 320px;
  }
}

@media screen and (max-width: 576px) {
  .eventDescriptionTextStyle {
    max-width: 366px;
  }
  .imgWrapperStyle {
    max-width: 366px;
    max-height: 320px;
  }
  .eventInsideContainerStyle {
    padding: 80px 24px;
    height: auto;
    justify-content: unset;
  }
  .eventInsideContainer2Style {
    padding: 80px 24px;
    height: auto;
    justify-content: unset;
  }
}
@media screen and (max-width: 450px) {
}
