.mainContainerStyle {
  position: relative;
}
.mainInsideContainerStyle {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
}
/* topHeader Styles start */
.outsideTopHeaderContainerStyle {
  background-color: rgba(0, 0, 0, 0.5);
}
/* topHeader Styles end */
/* return Styles start */
.outsideReturnContainerStyle {
  background-color: #1373fb;
}
/* return Styles end */
/* simpleHeader Styles start */
.outsideSoSImpleContainerStyle {
  background-color: #ffffff;
}
/* simpleHeader Styles end */
/* sustainability styles start  */
.outsideSustainabilityContainerStyle {
  background-color: rgba(0, 0, 0, 0.5);
}
/* sustainability styles end  */
