.inputMainContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
}
.labelNameStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 15px;
  line-height: 22px;
  color: #202020;
}
.inputContainerStyle {
  width: 100%;
  position: relative;
}
.arrowWrapperStyle {
  position: absolute;
  right: 16px;
  top: 12px;
}
.chatWrapperStyle {
  position: absolute;
  left: 24px;
  top: 12px;
  width: 24px;
  height: 24px;
}
.imgStyle {
  width: 100%;

  height: 100%;
  object-fit: cover;
}
.inputStyle {
  width: 100%;
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 24px;
  color: #a0a0a0;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #d0d0d0;
}
.inputStyle:focus {
  outline: none !important;
  border: 1px solid #1373fb;
}
.errorInputStyles {
  width: 100%;
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 24px;
  color: #a0a0a0;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #fa1363;
  background-color: #fa136314;
}
.phonNoContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #d0d0d0;
  padding: 12px 16px;
  border-radius: 8px;
}
.countryCodeTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-right: 16px;
}
.borderStyle {
  width: 1px;
  height: 100%;
  border-left: 2px solid #d0d0d0;
}
.phoneNoInputStyle {
  width: 100%;
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 24px;
  padding-left: 16px;
  color: #a0a0a0;
  border: none;
  outline: none;
}
.phonNoContainerStyle:focus-within {
  border: 1px solid #1373fb;
}
@media screen and (max-width: 1440px) {
  .labelNameStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .inputStyle {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 1279px) {
}

@media screen and (max-width: 1023px) {
}
@media screen and (max-width: 867px) {
}

@media screen and (max-width: 576px) {
}
