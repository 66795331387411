.productsContainerStyle {
  max-width: 1440px;
  margin: auto;
}
.productsInsideContainerStyle {
  padding: 100px 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.productsTabContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-bottom: 64px;
}
.tabTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #808080;
  white-space: nowrap;
  word-break: keep-all;
}
.selectedTabStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #000000;
  border-bottom: 2px solid #000000;
  white-space: nowrap;
  word-break: keep-all;
}
.productsListContainerStyle {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 16px;
}

.imgWrapperStyle {
  max-width: 362px;
  max-height: 224px;
  border-radius: 16px;
  transition: ease-in 0.25s;
}
.productWrapperStyle:hover .imgWrapperStyle {
  transform: scale(1.05);
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.productWrapperStyle {
  height: 380px;
  background-color: #f2f2f2;
  padding: 32px;
  border-radius: 16px;
  transition: height 0.4s ease-in-out;
}
.productWrapperStyle:hover {
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
  transform-origin: top;
  height: 400px;
  transition: height 0.4s ease-in-out;
}
.productNameTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #202020;
  margin-bottom: 8px;
}
.productDiscountPriceTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.productOriginalPriceTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #202020;
}
@media screen and (max-width: 1440px) {
  .tabTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .selectedTabStyle {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1366px) {
  .productsInsideContainerStyle {
    padding: 100px 128px;
  }
  .imgWrapperStyle {
    max-width: 266px;
    max-height: 224px;
  }
  .productNameTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1279px) {
  .productsInsideContainerStyle {
    padding: 100px 80px;
  }
  .imgWrapperStyle {
    max-width: 261px;
    max-height: 224px;
  }
  .productWrapperStyle {
    padding: 32px 24px;
  }
}

@media screen and (max-width: 1023px) {
  .productsInsideContainerStyle {
    padding: 80px 60px;
  }
  .productsTabContainerStyle {
    margin-bottom: 56px;
  }
  .imgWrapperStyle {
    max-width: 354px;
    max-height: 224px;
  }
  .productWrapperStyle {
    padding: 24px;
  }
  .productsListContainerStyle {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 867px) {
  .productsInsideContainerStyle {
    padding: 80px 32px;
  }
  .productsTabContainerStyle {
    width: 324px;
    margin-bottom: 48px;
    overflow: scroll;
  }
  .imgWrapperStyle {
    max-width: 404px;
    max-height: 224px;
  }
  .productsListContainerStyle {
    grid-template-columns: auto;
  }
}

@media screen and (max-width: 576px) {
  .productsInsideContainerStyle {
    padding: 80px 24px;
  }
  .productsTabContainerStyle {
    margin-bottom: 32px;
  }
  .imgWrapperStyle {
    max-width: 318px;
    max-height: 224px;
  }
}
