.mainPartnerContainerStyle {
  background-color: #ffffff;
}
.partnerContainerStyle {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;
}
.partnerDescTextStyle {
  max-width: 738px;
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 30px;
  line-height: 40px;
  color: #505050;
  margin-bottom: 64px;
}
.partnerImgContainerStyle {
  max-width: 782px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}

.imgWrapperStyle {
  width: 250px;
  height: 100px;
  background-color: #ebebeb;
  align-items: center;
  justify-content: center;
  border-radius: 84px;
  padding: 30px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
  .partnerContainerStyle {
    padding: 100px 0px;
  }
}
@media screen and (max-width: 1279px) {
  .partnerContainerStyle {
    padding: 100px 80px;
  }
}

@media screen and (max-width: 1023px) {
  .partnerContainerStyle {
    padding: 80px 60px;
  }
  .partnerImgContainerStyle {
    max-width: 460px;
  }
  .imgWrapperStyle {
    width: 200px;
    height: 100px;
  }
}
@media screen and (max-width: 867px) {
  .partnerContainerStyle {
    padding: 80px 32px;
  }
}

@media screen and (max-width: 559px) {
  .partnerContainerStyle {
    padding: 80px 24px;
  }
  .partnerImgContainerStyle {
    max-width: 366px;
  }
  .imgWrapperStyle {
    width: 100%;
    height: 100px;
  }
  .partnerDescTextStyle {
    text-align: left;
  }
}
