.outsideFooterContainerStyle {
  background-color: #000000;
}
.footerContainerStyle {
  max-width: 1440px;
  margin: auto;
  padding: 64px 80px 32px 80px;
}
.footerLinksStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footerlinkwrapperStyle {
  display: flex;
  justify-content: center;
}
.footerLinkIconStyle {
  font-size: 30px;
  line-height: 40px;
}
.footerlinkTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;
  /* border-bottom: 1px solid #ffffff; */
  text-decoration: underline;
  /* padding-bottom: 3px; */
  margin-bottom: 24px;
  cursor: pointer;
}
.footerlinkTextStyle:hover {
  border-bottom: none;
  text-decoration: none;
}
.footerlinkEmojiStyle {
  font-size: 30px;
  line-height: 40px;
  cursor: pointer;
}
.footerBottomContainer1Style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 64px;
}
.footerBottomContainer2Style {
  display: none;
}
.policyContainerStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}
.policyContainer2Style {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 12px;
}
.privacyTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #ebebeb;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 2px;
  cursor: pointer;
}
.socialContainerStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.socialContainer2Style {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 12px;
}

.socialIconWrapperStyle {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.copyRightsTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #ebebeb;
  cursor: pointer;
}
@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 1279px) {
}

@media screen and (max-width: 1023px) {
  .footerlinkTextStyle {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 18px;
  }
  .footerlinkEmojiStyle {
    font-size: 26px;
    line-height: 34px;
  }
  .footerlinkwrapperStyle {
    height: 55px;
  }
  .footerContainerStyle {
    padding: 56px 40px 28px 40px;
  }
}
@media screen and (max-width: 867px) {
  .footerBottomContainer1Style {
    display: none;
  }
  .footerBottomContainer2Style {
    display: flex;
    flex-direction: column;
    margin-top: 56px;
  }
  .footerContainerStyle {
    padding: 48px 32px 24px 40px;
  }
  .footerlinkTextStyle {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 18px;
  }
  .footerlinkEmojiStyle {
    font-size: 24px;
    line-height: 34px;
  }
}

@media screen and (max-width: 576px) {
  .footerContainerStyle {
    padding: 48px 24px 24px 24px;
  }
  .footerBottomContainer2Style {
    align-items: flex-start;
  }
  .footerLinksStyle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
