.eventOutsideContainerStyle {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.eventInsideContainerStyle {
  position: relative;
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 191px 80px;
  height: auto;
}
.eventHeaderTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 40px;
  line-height: 52px;
  color: #ffffff;
  margin-bottom: 16px;
  text-align: left;
}
.eventDescriptionTextStyle {
  max-width: 560px;
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #ebebeb;
  margin-bottom: 32px;
  text-align: left;
}
.codeTextWrapperStyle {
  width: fit-content;
  padding: 10px 30px;
  border: 3px dashed #ffffff;
  border-radius: 32px;
}
.codeTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;
}
.dotsWrapperStyle {
  display: flex;
  gap: 8px;
  margin-top: 200px;
}
.dotsStyle {
  width: 8px;
  height: 8px;
  border: 1px solid #ffffff;
  border-radius: 4px;
}
.dotsWhiteStyle {
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border-radius: 4px;
}

.arrowImgWrapperStyle {
  width: 24px;
  height: 24px;
}

.imageButtonsContainerStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 175px;
}

.imgWrapperStyle {
  position: relative;
  max-width: 850px;
  max-height: 452px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.leftArrowImgWrapperStyle {
  min-width: 40px;
  height: 40px;
}
.rightArrowImgWrapperStyle {
  min-width: 40px;
  height: 40px;
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
  .eventDescriptionTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1279px) {
  .eventInsideContainerStyle {
    padding: 191px 40px;
  }
}

@media screen and (max-width: 1023px) {
  .eventHeaderTextStyle {
    font-size: 36px;
    line-height: 44px;

    margin-bottom: 12px;
  }
  .eventDescriptionTextStyle {
    max-width: 452px;
  }
  .codeTextStyle {
    font-size: 26px;
    line-height: 34px;
  }
  .imgWrapperStyle {
    max-width: 720px;
    max-height: fit-content;
  }
  .eventInsideContainerStyle {
    padding: 147px 24px;
  }
  .codeTextStyle {
    font-size: 26px;
    line-height: 34px;
  }
}
@media screen and (max-width: 867px) {
  .leftArrowImgWrapperStyle {
    display: none;
  }
  .rightArrowImgWrapperStyle {
    display: none;
  }
  .eventHeaderTextStyle {
    font-size: 30px;
    line-height: 40px;
  }

  .imgWrapperStyle {
    max-width: 452px;
    max-height: 320px;
  }
  .codeTextStyle {
    font-size: 24px;
    line-height: 34px;
  }
  .eventInsideContainerStyle {
    padding: 149px 108px;
  }
}

@media screen and (max-width: 576px) {
  .eventDescriptionTextStyle {
    max-width: 366px;
  }
  .imgWrapperStyle {
    max-width: 366px;
    max-height: 320px;
  }
  .eventInsideContainerStyle {
    padding: 187px 24px;
  }
}
@media screen and (max-width: 450px) {
}
