.topHeaderContainerStyle {
  background-image: url("../../../../assets/Images/heroImg.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.topHeaderOutSideContainerStyle {
  backdrop-filter: blur(8px);
}
.topHeaderBlurContainerStyle {
  max-width: 1440px;
  margin: auto;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 217.5px 220px;
}
.topHeaderInsideContainerStyle {
  width: 840px;
  position: relative;
  display: flex;
  height: 232px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.headerTitleTextStyle {
  width: 452px;
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 48px;
  line-height: 64px;
  color: #fff8f8;
  text-align: left;
}
.headerDescContainerStyle {
  width: 452px;
  position: absolute;
  top: 130px;
  right: 0px;
}
.headerTitleDescriptionTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #ebebeb;
  text-align: left;
  margin-bottom: 32px;
}
.playBtnContainerStyle {
  width: 135px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.imgContainerStyle {
  width: 24px;
  height: 24px;
}
.playTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
  margin-left: 10px;
}
@media screen and (max-width: 1440px) {
  .playTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1366px) {
  .headerTitleDescriptionTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1279px) {
  .topHeaderBlurContainerStyle {
    height: 582px;
    padding: 175px 140px;
  }
}

@media screen and (max-width: 1023px) {
  .headerTitleTextStyle {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 24px;
  }
  .topHeaderInsideContainerStyle {
    width: 452px;
    display: block;
    height: 224px;
  }
  .headerDescContainerStyle {
    padding-left: 48px;
    top: 115px;
  }
  .topHeaderBlurContainerStyle {
    height: 496px;
    padding: 136px 244px;
  }
}
@media screen and (max-width: 867px) {
  .headerTitleTextStyle {
    font-size: 36px;
    line-height: 44px;
  }
  .topHeaderBlurContainerStyle {
    padding: 144px 108px;
  }
  .headerDescContainerStyle {
    top: 100px;
  }
}

@media screen and (max-width: 550px) {
  .headerTitleTextStyle {
    width: 100%;
  }
  .headerDescContainerStyle {
    padding-left: 32px;
  }
  .topHeaderBlurContainerStyle {
    height: 100vh;
    padding: 192px 24px;
  }
  .topHeaderInsideContainerStyle {
    width: 100%;
  }
  .headerDescContainerStyle {
    width: 100%;
  }
}
@media screen and (max-width: 425px) {
  .headerDescContainerStyle {
    top: 150px;
  }
}
@media screen and (max-width: 360px) {
  .headerDescContainerStyle {
    top: 180px;
  }
}
