.mainContainerStyle {
  position: relative;
  top: 64px;
}
.mainInsideContainerStyle {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
}
