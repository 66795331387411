.chatMainContainerStyle {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}
.topMenuNavContainerStyle {
  max-width: 1440px;
  padding: 18px 32px;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
}
.topAllChatsMenuContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.chatMenuTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.imgSmallWrapperStyle {
  width: 14px;
  height: 7px;
}

.imgWrapperStyle {
  width: 20px;
  height: 20px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.chatMsgContainerStyle {
  height: calc(100vh - 64px);
  display: flex;
}
.chatListContainerStyle {
  max-width: 496px;
  height: 100%;
}
.chatBoxContainerStyle {
  width: 100%;
  padding: 24px 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.chatProductImgStyle {
  padding: 16px;
  width: 56px;
  height: 56px;
  border-radius: 48px;
  background-color: #f2f2f2;
}
.chatProductImgWapperStyle {
  width: 24px;
  height: 24px;
}
.chatNameAnsMsgContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.productMsgTopContainerStyle {
  width: calc(100% - 56px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
.productNameTextStyle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.productSnoTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 15px;
  line-height: 22px;
  color: #808080;
}
.productChatDateTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 15px;
  line-height: 22px;
  color: #808080;
  white-space: nowrap;
  word-break: keep-all;
}
.productFinderTextStyle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 17px;
  line-height: 24px;
  color: #000000;
}
.productMsgTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 24px;
  color: #202020;
}
.noOfMsgTextWrapperStyle {
  width: 20px;
  height: 20px;
  background-color: #1373fb;
  border-radius: 16px;
  padding: 1px 6.5px;
}
.noOfMsgTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  white-space: nowrap;
  word-break: keep-all;
}
.chatMsgViewContainerStyle {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.chatMsgViewHeaderStyle {
  height: 80px;
  background-color: #f2f2f2;
  padding: 16px 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.chatMsgHeaderInsideWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-self: center;
  justify-content: center;
  align-items: center;
}
.chatMsgViewTopProductImgWrapperStyle {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background-color: #ffffff;
  padding: 12px;
}
.chatViewProductImgWapperStyle {
  width: 24px;
  height: 24px;
}
.chatMsgNameSnoWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
}
.chatViewProductNameTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 17px;
  line-height: 24px;
  color: #000000;
}
.chatViewProductSnoNoTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 15px;
  line-height: 22px;
  color: #505050;
}
.msgSContainerStyle {
  height: calc(100% - 170px);
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: scroll;
}
.msgOwnerWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: end;
  align-self: flex-start;
  gap: 10px;
  padding: 0 32px;
}
.msgSenderWrapperStyle {
  display: flex;
  flex-direction: row-reverse;
  align-items: end;
  align-self: flex-end;
  gap: 10px;
  padding: 0 32px;
}
.msgTextBlackWrapperStyle {
  width: fit-content;
  padding: 12px 16px;
  background-color: #f2f2f2;
  border-radius: 12px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 2px 16px rgba(19, 115, 251, 0.02);
}
.msgTextWhiteWrapperStyle {
  align-self: flex-start;
  width: fit-content;
  padding: 12px 16px;
  background-color: #1373fb;
  border-radius: 12px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 2px 16px rgba(19, 115, 251, 0.02);
}
.msgTextBlackStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 24px;
  color: #000000;
}
.msgTextWhiteStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 24px;
  color: #000000;
}
.msgInputContainerStyle {
  padding: 8px 32px 32px;
}
.chatInputStyle {
  border-radius: 24px;
  padding: 12px 64px;
  height: 48px;
}
/* chatModal styles  */
.popupChatContainerStyle {
  width: 274px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.popupChatTextWrapperStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.popupBlackTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #505050;
}
.popupBlueTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #1373fb;
}
/* chatModal styles end */
/* optiop popup styles */
.optionPopupContainerStyle {
  width: 274px;
  padding: 26px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.optionPopupOutsideContanerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.optionInsiseContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.iconWrapperStyle {
  width: 24px;
  height: 24px;
}
.optionPopuptextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #505050;
}
/* optiop popup styles end */
/* modal styles  */
.modalContainerStyle {
  width: 452px;
  background-color: #ffffff;
  border: none;
  outline: none;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.modalContentWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.modalBtnContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.modalHeaderTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.modalDescTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #505050;
}
.yesBtnStyle {
  width: 100%;
  background-color: #1373fb;
  border-radius: 28px;
  height: 48px;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  border: none;
  box-shadow: 0px 4px 16px rgba(19, 115, 251, 0.24);
}
.noBtnStyle {
  width: 100%;
  background-color: #ffffff;
  border-radius: 28px;
  height: 48px;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  border: 1px solid #000000;
  box-shadow: 0px 4px 16px rgba(235, 235, 235, 0.24);
}
/* modal styles end  */
@media screen and (max-width: 1440px) {
  .productSnoTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .productChatDateTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .productFinderTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .productMsgTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .chatViewProductNameTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .chatViewProductSnoNoTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .msgTextBlackStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .msgTextWhiteStyle {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 1279px) {
}

@media screen and (max-width: 1023px) {
  .chatListContainerStyle {
    max-width: 452px;
  }
}
@media screen and (max-width: 867px) {
  .chatBoxContainerStyle {
    padding: 16px 32px;
  }
  .chatMsgContainerStyle {
    display: block;
    max-width: 100%;
    height: calc(100vh);
  }

  .chatListContainerStyle {
    max-width: 867px;
    margin: auto;
  }
  .productMsgTopContainerStyle {
    width: calc(100%);
  }
  .chatNameAnsMsgContainerStyle {
    width: calc(100% - 64px);
  }
  .productNameTextStyle {
    max-width: calc(100% - 56px);
  }
  .productFinderTextStyle {
    max-width: calc(100% - 56px);
  }
}
@media screen and (max-width: 650px) {
}

@media screen and (max-width: 576px) {
  .chatBoxContainerStyle {
    padding: 16px 24px;
  }
  .topMenuNavContainerStyle {
    padding: 18px 24px;
  }
  .msgInputContainerStyle {
    padding: 8px 24px 24px;
  }
  .chatNameAnsMsgContainerStyle {
    width: calc(100% - 72px);
  }
  .modalContainerStyle {
    width: 100%;

    border-radius: 16px 16px 0 0;
  }
}
@media screen and (max-width: 400px) {
}
