.appContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin: auto;
}
.appImgWrapperStyle {
  width: 166.15px;
  height: 48px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 1279px) {
}

@media screen and (max-width: 1023px) {
  .appContainerStyle {
    gap: 24px;
  }
  .appImgWrapperStyle {
    width: 138.46px;
    height: 40px;
  }
}
@media screen and (max-width: 867px) {
}

@media screen and (max-width: 576px) {
  .appContainerStyle {
    flex-direction: column;
    gap: 24px;
  }
  .appImgWrapperStyle {
    width: 166.15px;
    height: 48px;
  }
}
