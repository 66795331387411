.loginContainerStyle {
  max-width: 1440px;
  margin: auto;
  padding: 33px 80px 100px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.closeContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.backTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #505050;
}

.closeIconWrapperStyle {
  width: 20px;
  height: 20px;
}

.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.loginInsideContainerStyle {
  width: 100%;
  margin: auto;
}
.loginAccountContainerStyle {
  max-width: 452px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.topContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.headerTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 26px;
  line-height: 34px;
  color: #000000;
}
.noAccTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #202020;
}
.signUpTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #1373fb;
  border-bottom: 1px solid #1373fb;
}
.inputContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.buttonContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.loginBtnStyle {
  border: none;
  background-color: #1373fb;
  padding: 12px;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
}
.googleBtnStyle {
  border: 1px solid #505050;
  background-color: #ffffff;
  padding: 12px;
  color: #505050;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
  outline: none;
}
.forgetTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #1373fb;
  text-align: right;
}
@media screen and (max-width: 576px) {
  .loginContainerStyle {
    padding: 33px 24px 100px;
  }
}
