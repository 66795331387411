.mainContainerStyle {
  position: relative;
  top: 64px;
}
.mainInsideContainerStyle {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
}
.aboutContainerStyle {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding: 64px 160px 100px;
}
@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
  .aboutContainerStyle {
    padding: 64px 128px 100px;
  }
}
@media screen and (max-width: 1279px) {
  .aboutContainerStyle {
    padding: 64px 80px 100px;
  }
}

@media screen and (max-width: 1023px) {
  .aboutContainerStyle {
    padding: 56px 60px 80px;
    gap: 80px;
  }
}
@media screen and (max-width: 867px) {
  .aboutContainerStyle {
    padding: 48px 32px 80px;
  }
}

@media screen and (max-width: 576px) {
  .aboutContainerStyle {
    padding: 32px 24px 80px;
  }
}
