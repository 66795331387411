.navOutsideContainerStyle {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(40px);
}
.navOutsideContainer2Style {
  background-color: #ffffff;
  border-bottom: 1px solid #ebebeb;
}
.navInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
}

.navContainerStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 80px;
  height: 64px;
}
.logoImgContainerStyle {
  max-width: 100px;
  height: 30px;
  cursor: pointer;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.reverseImgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: rotate(90deg);
}
.navLinksContainerStyle {
  width: 373.33px;
  display: flex;
  gap: 48px;
  align-items: center;
  justify-content: space-around;
}
.navLinkTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: #ebebeb;
  cursor: pointer;
}
.navLinkText2Style {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: #202020;
  cursor: pointer;
}

.navMenuContainerStyle {
  display: flex;
  align-items: center;
  gap: 25px;
}
.navMenuWrapperStyle {
  cursor: pointer;
  width: 24px;
}
.navMenuImgWrapperStyle {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.navMenuProfileImgWrapperStyle {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.mobileNavMenuWrapperStyle {
  display: none;
  align-items: center;
  gap: 25px;
}
.navWhiteContainerStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 40px;
  height: 64px;
  background-color: #ffffff;
  border-bottom: 1px solid #ebebeb;
}
.navMenuOptionsContainerStyle {
  background-color: #ffffff;
  padding: 24px 32px;
  display: grid;
  grid-template-columns: auto;
  gap: 16px;
  text-align: left;
  border-radius: 0px 0px 16px 16px;
}
.navMenuOptionsMobileContainerStyle {
  background-color: #ffffff;
  display: grid;
  grid-template-columns: auto;
  text-align: left;
  border-radius: 0px 0px 16px 16px;
}
.navMenuTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #202020;
  cursor: pointer;
}
.profileMenuOptionWrapperStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.signUpTopContainerStyle {
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 22px;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
}
.signUpTextStyle {
  width: 228px;
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.signUpBottomContainerStyle {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.popOverSignUpImgWrapperStyle {
  width: 64px;
  height: 64px;
  margin-bottom: 24px;
}
.signUpdescriptionTextStyle {
  width: 272px;
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #202020;
  text-align: center;
  margin-bottom: 24px;
}
.btnContainerStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.loginBtnStyle {
  width: 132px;
  height: 40px;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #000000;
}
.signUpBtnStyle {
  width: 132px;
  height: 40px;
  border: none;
  background-color: #1373fb;
  color: #ffffff;
}
.userProfileStyle {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.afterLoginTopContainerStyle {
  padding: 24px;
  display: flex;
  gap: 16px;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
}
.profileImageStyle {
  width: 40px;
  height: 40px;
}
.profileNameTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 2px;
}
.profileMailTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #505050;
}
.afterLoginMenuContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-bottom: 1px solid #ebebeb;
}
.afterLoginMenuMobileContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 0px 0px 16px 16px;
}
.afterLoginEditWrapperStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 90px;
}
.afterLoginEditProfileleftWrapperStyle {
  display: flex;
  gap: 12px;
}
.afterLoginMenuTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #505050;
}
.logoutTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #fa1363;
}
.languagePopoverContainerStyle {
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
  .navLinkTextStyle {
    font-size: 14px;
  }
}
@media screen and (max-width: 1279px) {
}

@media screen and (max-width: 1023px) {
  .navContainerStyle {
    padding: 20px 40px;
  }
  .logoImgContainerStyle {
    max-width: 93px;
    height: 28px;
  }
}
@media screen and (max-width: 867px) {
  .navContainerStyle {
    padding: 18px 32px;
  }
  .mobileNavMenuWrapperStyle {
    display: flex;
    align-items: center;
    gap: 25px;
  }
  .navMenuContainerStyle {
    display: none;
  }
  .navLinksContainerStyle {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .navContainerStyle {
    padding: 18px 24px;
  }
}
