.returnContainerStyle {
  max-width: 1440px;
  margin: auto;
  padding: 64px 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.returnTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;
}
.returnAnchorTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;
  border-bottom: 2px solid #ffffff;
  padding-bottom: 2px;
  margin-left: 5px;
  cursor: pointer;
}
/* modal styles  */
.modalMainContainerStyle {
  max-width: 588px;
  margin: auto;
  border: none;
  outline: none;
}
.topModalContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
}
.modalContainerStyle {
  background-color: #ffffff;
  padding: 32px;
  border-radius: 16px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.needHelpTextStyle {
  font-family: "Euclid-Circular-B-Regular", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 9px 16px;
}
.closeContainerStyle {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}
.imgWrapperStyle {
  width: 20px;
  height: 20px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.modalContentStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.modalHeaderTextStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 26px;
  line-height: 34px;
  color: #000000;
}
.modalDescTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.scanTextStyle {
  width: fit-content;
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #1373fb;
  border-bottom: 1px solid #1373fb;
  text-align: center;
}
/* modal styles end  */
@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
  .returnContainerStyle {
    padding: 64px 128px;
  }
}
@media screen and (max-width: 1279px) {
  .returnContainerStyle {
    padding: 64px 80px;
  }
}

@media screen and (max-width: 1023px) {
  .returnContainerStyle {
    padding: 40px 60px;
  }
  .returnTextStyle {
    font-size: 26px;
    line-height: 34px;
  }
  .returnAnchorTextStyle {
    font-size: 26px;
    line-height: 34px;
    border-bottom: 2px solid #ffffff;
    padding-bottom: 2px;
    margin-left: 5px;
  }
}
@media screen and (max-width: 867px) {
  .returnContainerStyle {
    padding: 40px 32px;
  }
  .returnTextStyle {
    font-size: 24px;
    line-height: 34px;
  }
  .returnAnchorTextStyle {
    font-size: 24px;
    line-height: 34px;
    border-bottom: 2px solid #ffffff;
    padding-bottom: 2px;
    margin-left: 5px;
  }
}

@media screen and (max-width: 576px) {
  .returnContainerStyle {
    padding: 32px 24px;
    display: block;
    text-align: left;
  }
  .modalContainerStyle {
    background-color: #ffffff;
    padding: 32px;
    border-radius: 16px 16px 0 0;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
}
