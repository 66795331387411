.learnInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
  padding: 100px 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.learnTopDescTextStyle {
  max-width: 738px;
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 100px;
}
.learnFoundiHeaderTextStyle {
  max-width: 1120px;
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 40px;
  line-height: 52px;
  color: #000000;
  margin-bottom: 64px;
}

.learnBoxContainerStyle {
  background-color: #f2f2f2;
  padding: 64px;
  display: flex;
  gap: 64px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
}
.leftBoxContentStyle {
  /* max-width: 668px; */
}
.learnImgWrapperStyle {
  min-width: 260px;
  height: 248px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.headerTextLeftStyle {
  font-family: "Euclid-Circular-B-Medium", sans-serif;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 16px;
  text-align: left;
}
.contentWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.contentTextStyle {
  font-family: "Euclid-Circular-B-Light", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #202020;
  text-align: left;
}
.cardsOutsideContainerStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 16px;
}
.cardContainerStyle {
  background-color: #f2f2f2;
  border-radius: 16px;
  padding: 32px;
  text-align: left;
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
  .learnInsideContainerStyle {
    padding: 100px 128px;
  }
  .contentTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1279px) {
  .learnInsideContainerStyle {
    padding: 100px 80px;
  }
}

@media screen and (max-width: 1023px) {
  .learnInsideContainerStyle {
    padding: 80px 64px;
  }
  .learnTopDescTextStyle {
    max-width: 668px;
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 80px;
  }
  .learnFoundiHeaderTextStyle {
    max-width: 820px;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 56px;
  }
  .learnBoxContainerStyle {
    padding: 56px;
    gap: 56px;
  }
  .headerTextLeftStyle {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 12px;
  }
  .cardsOutsideContainerStyle {
    gap: 12px;
    margin-top: 12px;
  }
  .cardContainerStyle {
    padding: 24px;
  }
}
@media screen and (max-width: 867px) {
  .learnInsideContainerStyle {
    padding: 80px 32px;
  }
  .learnTopDescTextStyle {
    max-width: 560px;
    font-size: 24px;
    line-height: 34px;
  }
  .learnFoundiHeaderTextStyle {
    max-width: 604px;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 48px;
  }
  .learnBoxContainerStyle {
    padding: 24px;
    flex-direction: column;
    gap: 24px;
  }
  .cardsOutsideContainerStyle {
    flex-direction: column;
  }
}

@media screen and (max-width: 576px) {
  .learnInsideContainerStyle {
    padding: 80px 24px;
  }
  .learnTopDescTextStyle {
    max-width: 366px;
    text-align: left;
  }
  .learnFoundiHeaderTextStyle {
    max-width: 366px;
    text-align: left;
    margin-bottom: 32px;
  }
  .headerTextLeftStyle {
    font-size: 24px;
  }
}
